import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import MainMenu from "./mainMenu"
import { Bars3Icon } from "@heroicons/react/24/solid"

const HeaderEmbed = () => {
  return (
    <header>
      <div className="rbGradient h-2 w-full"></div>
      <div className="absolute z-10 w-full">
        <nav className="my-8 mx-auto flex items-center gap-8 justify-between px-4 sm:px-8">
          <a className="sr-only" href="#blocks">
            Skip to Main Content
          </a>
          <Link className="navbar-brand" to="/">
            <StaticImage
              height={48}
              width={170}
              placeholder="blurred"
              objectFit="contain"
              className="w-40"
              src="../images/SummitLogo.png"
              alt="TransTech Logo"
            />
          </Link>
          <div className="hidden md:flex items-center gap-8">
            <MainMenu />
            <div className="w-60 flex flex-nowrap items-center gap-4 font-bold uppercase">
              <a
                href="https://transtech.me/summitapp"
                className="w-44 text-center md:w-auto rounded-full border-2 border-black bg-gradient-to-r from-ttGreen to-ttBlue py-2 px-4 text-white drop-shadow-harsh hover:relative hover:top-1 hover:left-1 hover:cursor-pointer hover:drop-shadow-none"
                target="_blank"
              >
                Attend
              </a>
              <a
                href="https://registration.socio.events/e/2024transtechsummit"
                className="w-44 text-center md:w-auto rounded-full border-2 border-black bg-gradient-to-r from-ttOrange via-ttRed to-ttPurple py-2 px-4 text-white drop-shadow-harsh hover:relative hover:top-1 hover:left-1 hover:cursor-pointer hover:drop-shadow-none"
                target="_blank"
              >
                Register
              </a>
            </div>
          </div>
          <details className="md:hidden">
            <summary className="menu-btn flex font-bold text-center rounded-full border-2 border-black bg-gradient-to-r from-ttOrange via-ttRed to-ttPurple py-2 px-4 text-white drop-shadow-harsh hover:relative hover:top-1 hover:left-1 hover:cursor-pointer hover:drop-shadow-none">
              Menu <Bars3Icon className="inline w-[24px]" />
            </summary>
            <nav className="fixed -z-10 bg-ttGray top-0 left-0 h-full w-full">
              <div className="rbGradient h-2 w-full"></div>

              <div className="h-full w-full pt-[160px] pb-[80px]">
                <MainMenu />
              </div>
            </nav>
          </details>
        </nav>
      </div>
      <div className="overflow-hidden"></div>
    </header>
  )
}

export default HeaderEmbed
