import React from "react"
import HeaderEmbed from "./headerEmbed"

const EmbedLayout = ({ children }) => {
  return (
    <>
      <div className="relative">
        <HeaderEmbed />
        <main>{children}</main>
      </div>
    </>
  )
}

export default EmbedLayout
