import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import JotformEmbed from "react-jotform-embed"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import EmbedLayout from "../components/embedLayout"
import Layout from "../components/layout"
import Seo from "../components/seo"

const PageTemplate = ({ data: { post } }) => {
  const featuredImage = {
    src:
      post?.featuredImage?.node.resize.src ||
      "https://wp.transtechsocial.org/wp-content/uploads/2022/11/TransTech-Oct-29_20976-edit.jpg",
  }

  if (post.pageACF.embedPage) {
    return (
      <EmbedLayout>
        <div className="absolute z-0 w-full bg-gray-200 h-[110px]"></div>
        <div
          className={
            post.pageACF.colorScheme + " md:absolute -z-10 -top-[40px] w-full"
          }
          style={{ height: "1546px" }}
        >
          {parse(post.content)}
        </div>
      </EmbedLayout>
    )
  } else {
    return (
      <Layout>
        <div className={post.pageACF.colorScheme}>
          <div className="relative">
            <div className="">
              <div
                className="absolute opacity-40 -z-20 h-full w-full top-0 bg-cover bg-top"
                style={{
                  backgroundImage: "url(" + featuredImage.src + ")",
                }}
              >
                <div className="absolute -z-10 h-full w-full top-0 mix-blend-screen"></div>
                <div
                  className="absolute -z-10 h-[200px] sm:h-[150px] md:h-[200px] lg:h-[400px] bottom-0 w-full bg-contain"
                  style={{
                    backgroundImage:
                      "url(https://wp.transtechsocial.org/wp-content/uploads/2023/01/white-fade-2.png)",
                  }}
                ></div>
              </div>
            </div>
            <div id="title" className="max-w-7xl mx-auto px-4 sm:px-8">
              <div className="w-full pt-32 xl:pt-72 pb-0 relative z-0">
                <h1
                  itemProp="headline"
                  className="w-full font-hero text-4xl md:text-6xl lg:text-7xl leading-none text-black mb-4 md:mb-12 lg:mb-24"
                >
                  {parse(post.title)}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className={post.pageACF.colorScheme + " " + post.slug + " page"}>
          <div id="page-content" className="max-w-7xl mx-auto px-4 sm:px-8">
            <div className="relative flex flex-col lg:flex-row justify-between gap-8 mb-32">
              <section
                id="blocks"
                itemProp="articleBody"
                className="entry-content w-full mt-8 md:mt-0"
              >
                {!!post.content && parse(post.content)}
                {post.jotformUrl.jotformUrl && (
                  <>
                    <i>
                      If the form doesn't load below,{" "}
                      <a
                        href={post.jotformUrl.jotformUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        click here to go to the form
                      </a>
                    </i>
                    <div id="form">
                      <JotformEmbed src={post.jotformUrl.jotformUrl} />
                    </div>
                  </>
                )}
              </section>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query PageById($id: String!) {
    post: wpPage(id: { eq: $id }) {
      id
      content
      title
      slug
      date(formatString: "MMMM DD, YYYY")
      jotformUrl {
        jotformUrl
      }
      pageACF {
        colorScheme
        embedPage
      }
      featuredImage {
        node {
          altText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                webpOptions: { quality: 90 }
                width: 1920
                formats: WEBP
                layout: FIXED
                aspectRatio: 2
              )
            }
          }
          resize(
            fit: COVER
            width: 1920
            aspectRatio: 1.778
            quality: 90
            format: WEBP
          ) {
            height
            src
            width
          }
        }
      }
      smartcrawlSeo {
        metaDesc
        title
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
      }
    }
  }
`

export const Head = ({ data: { post } }) => {
  return (
    <Seo
      title={parse(post.title) + " | TransTech Summit"}
      description={post.smartcrawlSeo?.metaDesc}
      ogTitle={post.opengraphTitle}
      ogDescription={post.smartcrawlSeo?.opengraphDescription}
      twTitle={post.smartcrawlSeo?.twitterTitle}
      twDescription={post.smartcrawlSeo?.twitterDescription}
      featuredImage={post?.featuredImage?.node?.mediaItemUrl}
      ogImage={post.smartcrawlSeo?.opengraphImage?.mediaItemUrl}
      twImage={post.smartcrawlSeo?.twitterImage?.mediaItemUrl}
    />
  )
}
